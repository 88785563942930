import {red} from '@material-ui/core/colors';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import App from './App';
import {AuthProvider} from './auth/AuthProvider';
import './index.css';
import * as serviceWorker from './serviceWorker';
import moment from 'moment';
import 'moment/locale/de';
import {MuiThemeProvider} from '@material-ui/core';

// TODO: fix sentry bug #163186850
// Sentry.init({
//     dsn: 'https://e5ebd0c2b25440528e35d43a3e472b2a@sentry.io/1356351'
// });
moment.locale('de');

const theme = {
    palette: {
        primary: {
            light: '#fff851',
            main: '#fdc508',
            dark: '#c59500',
            contrastText: '#000'
        },
        secondary: {
            main: '#3b454d',
            contrastText: '#ffffff'
        },
        error: red
    },
    overrides: {
        MuiDrawer: {
            paper: {
                zIndex: 1000,
                top: '64px',
                width: '200px'
            }
        }
    }
};

ReactDOM.render(
    <AuthProvider>
        <MuiThemeProvider theme={createMuiTheme(theme)}>
            <App />
        </MuiThemeProvider>
    </AuthProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
